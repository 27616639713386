import React from "react";
import "./directorprofile.css";
import PushpaImg from "../../assets/pushpa.jpeg";
import PrayashImg from "../../assets/Prayash.jpg";
import HariImg from "../../assets/Hari.jpeg";
import NiteshImg from "../../assets/nitesh.jpeg";
import SarojImg from "../../assets/saroj.jpeg";
import SahilImg from "../../assets/sahil.jpeg";
import SandeshImg from "../../assets/sandesh.jpeg";
import SundarImg from "../../assets/sundar.jpg";
import AbhishekImg from "../../assets/abhishek.jpg";
import GulshanImg from "../../assets/gulshan.jpeg";
import HemImg from "../../assets/hem.jpeg";
import AgriExpertProfile from "./AgriExpertProfile";

const MemberData = [
  {
    memberId: 0,
    image: PrayashImg,
    name: "Prayash Basnet",
    designation: "Business Development Officer",
  },
  {
    memberId: 1,
    image: HariImg,
    name: "Er. Humanath Pokhrel",
    designation: "Full Stack Developer",
  },
  {
    memberId: 2,
    image: NiteshImg,
    name: "Nitesh Mandal",
    designation: "Flutter Developer",
  },
  {
    memberId: 3,
    image: GulshanImg,
    name: "Gulshan Kumar Mahaseth",
    designation: "AI/ML Developer",
  },
  {
    memberId: 4,
    image: HemImg,
    name: "Hem Moktan",
    designation: "UI/UX Designer | Graphics Designer",
  },
  {
    memberId: 5,
    image: SarojImg,
    name: "Er. Saroj Chaudhary",
    designation: "IoT & Firmware Engineer",
  },
  {
    memberId: 6,
    image: SahilImg,
    name: "Sahil Thapa",
    designation: "IoT Developer",
  },
  {
    memberId: 7,
    image: AbhishekImg,
    name: "Abhisekh Pandey",
    designation: "Junior Developer",
  },
  {
    memberId: 8,
    image: SandeshImg,
    name: "Sandesh Paudel",
    designation: "Junior Developer",
  },
  {
    memberId: 9,
    image: SundarImg,
    name: "Sundar Gurung",
    designation: "Junior Developer",
  },
];

const CompanyProfile = () => {
  return (
    <>
      <div className="container-fluid mb-5 company-main">
        <div className="container director-profile pt-5 pb-5">
          <h1 className="director-profile-title">Muktinath iTech Team</h1>
          <div className="row mt-5 head-content">
            <div className="col-md-5 head-img">
              <img src={PushpaImg} alt="Pushpa Raj Joshi" />
            </div>
            <div className="col-md-6 text-start head-text">
              <h1 className="head-name">Mr.Pushpa Raj Joshi</h1>
              <p className="head-designation">Head of IT</p>
              <p className="head-intro">
                Mr. Pushpa Raj Joshi, a resident of Baneshwor, Kathmandu,
                currently serves as the IT Head at Muktinath Krishi Company Ltd.
                His academic journey led him to earn a Bachelor's degree in
                Electronics and Communication from Himalaya College of
                Engineering. Throughout his career, he has held diverse roles
                including Network Administrator, Broadcast Engineer, Senior IT
                Officer, and Deputy Chief Executive Officer in esteemed
                organizations. Mr. Joshi's professional trajectory is marked by
                continuous learning and skill enhancement. He has undergone
                several training programs to augment his expertise. His
                proficiency spans across various domains including Networking
                and Firewall Devices, Linux Operating System, Database
                management with MySql and SQL Server, Data Analysis, IoT-based
                Mobile and Web applications, Configuration of ISP-related
                equipment, RF Wireless Transmission, VMware, and High
                Availability (HA) modes. With a specialization in Electronics
                and Communication Engineering, Mr. Joshi brings a wealth of
                experience and knowledge to his current role, contributing
                significantly to the success and technological advancement of
                his organization.
              </p>
            </div>
          </div>
          <div className="row member-content mt-5">
            {MemberData.map((items, index) => (
              <div key={items.memberId} className="col-lg-3 col-md-4 col-sm-6">
                <div className="member-img">
                  <img src={items.image} alt={items.name} />
                </div>
                <p className="member-name mt-4">{items.name}</p>
                <p className="member-designation">{items.designation}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <AgriExpertProfile /> */}
    </>
  );
};

export default CompanyProfile;
