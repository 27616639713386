import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import "./portfoliopagecard.css";
import ImagePortfolio1 from "../../assets/portfolio_img1.jpeg";
import ImagePortfolio2 from "../../assets/portfolio_img2.jpeg";
import VideoPortfolio1 from "../../assets/video_portfolio1.mp4";

const mediaData = [
  {
    id: 0,
    type: "image",
    src: ImagePortfolio1,
    title: "Green House Automation System",
    description:
      "Our passion is figuring out how to navigate the always changing world of technology and create solutions that go above and beyond in Agriculture.",
  },
  {
    id: 1,
    type: "image",
    src: ImagePortfolio2,
    title: "Green House Automation Control Board",
    description:
      "Urban Farming is the future of Agriculture, and we are leading the way.",
  },
  {
    id: 2,
    type: "video",
    src: VideoPortfolio1,
    title: "Itech Office Workspace",
    description:
      "We are a team of passionate developers and designers who are dedicated to creating innovative solutions that make a difference in the world.",
  },
];

const PortfolioPageCard = () => {
  const [activeTab, setActiveTab] = useState("images");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const imageCount = mediaData.filter((item) => item.type === "image").length;
  const videoCount = mediaData.filter((item) => item.type === "video").length;

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const openModal = (item) => {
    setModalContent(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className="portfolio-container container h-screen">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "images" ? "active" : ""}`}
          onClick={() => setActiveTab("images")}
        >
          Images ({imageCount})
        </button>
        <button
          className={`tab ${activeTab === "videos" ? "active" : ""}`}
          onClick={() => setActiveTab("videos")}
        >
          Videos ({videoCount})
        </button>
      </div>
      <div className="content my-5">
        {mediaData
          .filter((item) => item.type === activeTab.slice(0, -1))
          .map((item) => (
            <div key={item.id} className="card" onClick={() => openModal(item)}>
              {item.type === "image" ? (
                <img
                  src={item.src || "/placeholder.svg"}
                  alt={item.title}
                  className="card-media"
                />
              ) : (
                <div className="video-container">
                  <video
                    className="video-placeholder"
                    autoPlay
                    muted
                    loop
                    poster={item.src} // Display the first frame as preview
                    width="100%"
                    height="auto"
                  >
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="play-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="play-icon"
                    >
                      <polygon points="5 3 19 12 5 21 5 3"></polygon>
                    </svg>
                  </div>
                </div>
              )}
              <div className="card-content">
                <h3 className="card-title">{item.title}</h3>
                <p className="card-description">{item.description}</p>
              </div>
            </div>
          ))}
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
              <X size={24} />
            </button>
            {modalContent.type === "image" ? (
              <img
                src={modalContent.src}
                alt={modalContent.title}
                className="modal-media"
              />
            ) : (
              <video controls className="modal-media" width="100%">
                <source src={modalContent.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioPageCard;
