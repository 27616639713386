import React from "react";
import "./aboutpage.css";
import ContactSection from "../Home/ContactSection/ContactSection";
import GoToTop from "../GoToTop";
import ServicePageImage1 from "../../assets/servicepage-image1.webp";
import ServicePageImage2 from "../../assets/servicepage-image2.webp";
import ServicePageImage3 from "../../assets/servicepage-image3.webp";
import DirectorProfile from "./DirectorProfile";
import CompanyProfile from "./CompanyProfile";
import ManagementProfile from "./ManagementProfile";
import {
  BarChart,
  ChartNoAxesColumnIcon,
  Cloud,
  Cog,
  Cpu,
  Tractor,
  Users,
  Wifi,
} from "lucide-react";

const AboutPage = () => {
  return (
    <>
      <div className="container-fluid servicepage-main py-5 mb-0">
        <div className="container servicepage">
          <div className="row  d-flex justify-content-center align-items-center">
            <div className="col-lg-8 text-content">
              <h1 className="servicepage-title">
                About <br /> Muktinath iTech
              </h1>
              <p className="servicepage-para mt-4">
                Discover tailored IT solutions for streamlined operations and
                accelerated growth. From software development to cybersecurity,
                we empower businesses to thrive in the digital age. Elevate your
                success with us.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mission-section">
        <div className="container mission-content">
          <div className="mission-header">
            <h1 className="mission-title">Our Aim</h1>
            <p className="mission-para">
              Developing Innovation, Accelerating Change: Connecting
              Sustainability and Technology for a Smarter, More Connected World.
            </p>
          </div>
          <div className="mission-list mt-3">
            <div className="row d-flex justify-content-between align-items-start">
              <div className="row d-flex justify-content-between align-items-start">
                <div className="col-md-5">
                  <h4 className="mb-4">
                    <b>Information Technology Sector</b>
                  </h4>

                  <div className="text-center mb-4">
                    <Cpu className="text-primary" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Drive Innovation</b>
                    </h5>
                    <p className="text-muted">
                      Design and develop mobile apps, websites, and digital
                      platforms to meet modern technological demands and drive
                      business growth.
                    </p>
                  </div>

                  <div className="text-center mb-4">
                    <Wifi className="text-info" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Enable Connectivity</b>
                    </h5>
                    <p className="text-muted">
                      Provide advanced data processing, secure networking, and
                      wireless connectivity solutions to ensure seamless
                      operations and informed decision-making.
                    </p>
                  </div>

                  <div className="text-center mb-4">
                    <Cloud className="text-success" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Adopt Advanced Technologies</b>
                    </h5>
                    <p className="text-muted">
                      Leverage Artificial Intelligence, Machine Learning, and
                      Cloud Computing to create scalable and efficient IT
                      systems.
                    </p>
                  </div>

                  <div className="text-center mb-4">
                    <BarChart className="text-warning" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Transform Businesses</b>
                    </h5>
                    <p className="text-muted">
                      Enable digital transformation by offering sustainable,
                      user-friendly technologies that improve productivity and
                      adaptability.
                    </p>
                  </div>
                </div>

                <div className="col-md-5">
                  <h4 className="mb-4">
                    <b>Agricultural Sector</b>
                  </h4>

                  <div className="text-center mb-4">
                    <Tractor className="text-success" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Smart Farming Solutions</b>
                    </h5>
                    <p className="text-muted">
                      Implement technologies like AI, Blockchain, and IoT to
                      enhance productivity, optimize resource use, and support
                      precision farming.
                    </p>
                  </div>

                  <div className="text-center mb-4">
                    <Users className="text-primary" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Empower Farmers</b>
                    </h5>
                    <p className="text-muted">
                      Develop accessible tools and platforms to help farmers
                      reduce costs, improve sustainability, and enhance market
                      access.
                    </p>
                  </div>

                  <div className="text-center mb-4">
                    <ChartNoAxesColumnIcon className="text-info" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Data-Driven Insights</b>
                    </h5>
                    <p className="text-muted">
                      Utilize data analytics to monitor soil health, water
                      usage, and crop performance, driving smarter
                      decision-making.
                    </p>
                  </div>

                  <div className="text-center mb-4">
                    <Cog className="text-warning" size={40} />
                    <h5 className="text-dark mt-2">
                      <b>Foster Collaboration</b>
                    </h5>
                    <p className="text-muted">
                      Partner with agricultural institutions, researchers, and
                      policymakers to promote agritech innovations and skill
                      development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DirectorProfile />
      {/* <ManagementProfile /> */}
      <CompanyProfile />
      <ContactSection />
      <GoToTop />
    </>
  );
};

export default AboutPage;
