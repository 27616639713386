import React from "react";
import "./directorprofile.css";
import SitaramImg from "../../assets/sitaram-kaphle.jpg";
import BharatImg from "../../assets/bharatraj-dhakal.jpg";
import TulsiramImg from "../../assets/tulsiram-dhakal.jpg";
import GovindaImg from "../../assets/govindabahadur-raut.jpg";
import SugarikaImg from "../../assets/sugarika-kc.jpg";
import GajendraImg from "../../assets/gajendra-man-shrestha.jpeg"
import RamsharanImg from "../../assets/ramsharan-timalsina.jpg";

const MemberData = [
  {
    memberId: 0,
    image: BharatImg,
    name: "Mr. Bharat Raj Dhakal",
    designation: "Director",
  },
  {
    memberId: 1,
    image: TulsiramImg,
    name: "Mr. Tulasi Ram Dhakal",
    designation: "Director",
  },
  {
    memberId: 2,
    image: GovindaImg,
    name: "Mr. Govinda Bahadhur Raut",
    designation: "Director",
  },
  {
    memberId: 3,
    image: SugarikaImg,
    name: "Mrs. Sugarika K.C.",
    designation: "Director",
  },
  {
    memberId:4,
    image: GajendraImg,
    name:"Gajendra Man Shrestha",
    designation:"Director"
  },
  {
    memberId: 5,
    image: RamsharanImg,
    name: "Mr. Ram Sharan Timalsina",
    designation: "Deputy General Manager & CS",
  },
];

const DirectorProfile = () => {
  return (
    <>
      <div className="container-fluid mb-5">
        <div className="container director-profile">
          <h1 className="director-profile-title">Board of Directors</h1>
          <div className="row mt-5 head-content">
            <div className="col-md-5 head-img">
              <img src={SitaramImg} alt="Sitaram Kaphle" />
            </div>
            <div className="col-md-6 text-start head-text">
              <h1 className="head-name">Mr.Sitaram Kaphle</h1>
              <p className="head-designation">Chairperson</p>
              <p className="head-intro">
                Mr. Sitaram Kaphle is a visionary leader and a highly qualified
                and result-oriented professional with over 26 years of
                diversified experience in the financial sector, cooperative,
                corporate strategy, financial management, Credit Management,
                Human Resource Management, as well as overall management of the
                organization. On 2079-12-14, he assumed the role of CEO at MKCL,
                having previously served as Managing Director since 2055 and
                founding chairperson of the organization. He is a resident of
                Tokha Muncipality-05, Dhapasi Height, Kathmandu. Nepal. He holds
                his Master's degree in Business Administration.
              </p>
            </div>
          </div>
          <div className="row mt-5 member-content ">
            {MemberData.map((items) => (
              <div key={items.memberId} className="col-lg-3 col-md-4 col-sm-6">
                <div className="member-img">
                  <img src={items.image} alt={items.name} />
                </div>
                <p className="member-name mt-4">{items.name}</p>
                <p className="member-designation">{items.designation}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectorProfile;
