import React from "react";
import "./servicepage.css";
import ContactSection from "../Home/ContactSection/ContactSection";
import ServicePageImage1 from "../../assets/servicepage-image1.webp";
import ServicePageImage2 from "../../assets/servicepage-image2.webp";
import ServicePageImage3 from "../../assets/servicepage-image3.webp";
import ServicepageCard from "./ServicepageCard";
import GoToTop from "../GoToTop";

const ServicePage = () => {
  return (
    <>
      <div className="container-fluid servicepage-main py-5">
        <div className="container servicepage">
          <div className="row  d-flex justify-content-center align-items-center">
            <div className="col-lg-8 text-content">
              <h1 className="servicepage-title">
                Innovative IT Solutions for the Modern Enterprise
              </h1>
              <p className="servicepage-para mt-4">
                Discover tailored IT solutions for streamlined operations and
                accelerated growth. From software development to cybersecurity,
                we empower businesses to thrive in the digital age. Elevate your
                success with us.
              </p>
            </div>
          </div>

          {/* <div className="row image-content d-flex">
            <div className="col image-card1">
              <img src={ServicePageImage1} alt="app development" />
            </div>
            <div className="col image-card2">
              <img src={ServicePageImage2} alt="web design" />
            </div>
            <div className="col image-card3">
              <img src={ServicePageImage3} alt="tech in agriculture" />
            </div>
          </div> */}
        </div>
      </div>
      <ServicepageCard />
      <ContactSection />
      <GoToTop />
    </>
  );
};

export default ServicePage;
